import { format, getDayOfYear, differenceInMinutes, addDays } from "date-fns";

// const style = getComputedStyle(document.body);
// const monofont = style.getPropertyValue("--mono-font");
const currentLocationColor = "rgba(51,224,255,1)";

function formatSunriseValues(data) {
  const sunrises = data.map((item) => {
    let sunriseHours =
      (item.sunrise.time.getHours() * 60 + item.sunrise.time.getMinutes()) / 60;

    if (
      item.sunrise.midnightSun ||
      item.sunset.midnightSun ||
      item.sunrise.polarNight ||
      item.sunset.polarNight
    ) {
      sunriseHours = 0;
    }

    return {
      x: format(item.date, "d.M."),
      y: sunriseHours,
      polarNight: item.sunrise.polarNight,
      midnightSun: item.sunrise.midnightSun,
    };
  });
  return sunrises;
}

function formatSunsetValues(data) {
  const sunsets = data.map((item) => {
    let sunsetMinutes =
      item.sunset.time.getHours() * 60 + item.sunset.time.getMinutes();

    if (
      item.sunset.time.getMonth() > 3 &&
      item.sunset.time.getMonth() < 8 &&
      sunsetMinutes < 200
    ) {
      sunsetMinutes = sunsetMinutes + 1440;
      if (sunsetMinutes > 1440) {
        sunsetMinutes = 1440;
      }
    }
    if (item.sunrise.midnightSun || item.sunset.midnightSun) {
      sunsetMinutes = 1440;
    }

    if (item.sunrise.polarNight || item.sunset.polarNight) {
      sunsetMinutes = 0;
    }

    return {
      x: format(item.date, "d.M."),
      y: sunsetMinutes / 60,
      polarNight: item.sunset.polarNight,
      midnightSun: item.sunset.midnightSun,
    };
  });
  return sunsets;
}

function formatDayLengthValues(data) {
  const dayLengths = data.map((item, idx) => {
    const sunrise = item.sunrise.time;
    let sunset = item.sunset.time;

    if (item.sunset.time < item.sunrise.time) {
      sunset = addDays(sunset, 1);
    }
    let length = differenceInMinutes(sunset, sunrise) / 60;

    let y = length;
    if (
      (item.date.getMonth() > 3 &&
        item.date.getMonth() < 9 &&
        (length < 2 || length > 24)) ||
      item.sunrise.midnightSun ||
      item.sunset.midnightSun
    ) {
      y = 24;
    }
    if (
      (item.date.getMonth() > 9 || item.date.getMonth() < 2) &&
      (length > 12 || length < 0)
    ) {
      y = Math.abs(Math.abs(length) - 12);
    }
    if (item.sunrise.polarNight || item.sunset.polarNight) {
      y = 0;
    }

    const dayLength = {
      x: format(item.date, "d.M."),
      y,
      length: length,
      midnightSun: item.sunset.midnightSun,
      polarNight: item.sunrise.polarNight,
    };

    return dayLength;
  });

  return dayLengths;
}

export function getChartData(visibleChart, location, comparisions) {
  let locationData = [];

  if (visibleChart === "sunTimes") {
    locationData = [
      {
        label: location.name,
        data: formatSunriseValues(location.yearlySunTimes),
        borderColor: currentLocationColor,
        backgroundColor: currentLocationColor,
        borderWidth: 2,
        order: 5,
      },
      {
        label: location.name,
        data: formatSunsetValues(location.yearlySunTimes),
        borderColor: currentLocationColor,
        backgroundColor: currentLocationColor,
        borderWidth: 2,
      },
    ];
  }

  if (visibleChart === "dayLength") {
    locationData = [
      {
        label: location.name,
        data: formatDayLengthValues(location.yearlySunTimes),
        borderColor: currentLocationColor,
        backgroundColor: currentLocationColor,
        borderWidth: 2,
        order: 5,
      },
    ];
  }

  let comparisionData = [];

  if (comparisions.length > 0) {
    comparisions.forEach((item, idx) => {
      if (!item.hidden) {
        if (visibleChart === "dayLength") {
          comparisionData.push({
            label: item.name,
            data: formatDayLengthValues(item.yearlySunTimes),
            borderColor: `rgba(${item.color.rgba},1)`,
            backgroundColor: `rgba(${item.color.rgba},1)`,
            borderWidth: 2,
          });
        } else {
          comparisionData.push(
            {
              label: item.name,
              data: formatSunriseValues(item.yearlySunTimes),
              borderColor: `rgba(${item.color.rgba},1)`,
              backgroundColor: `rgba(${item.color.rgba},1)`,
              borderWidth: 2,
            },
            {
              label: item.name,
              data: formatSunsetValues(item.yearlySunTimes),
              borderColor: `rgba(${item.color.rgba},1)`,
              backgroundColor: `rgba(${item.color.rgba},1)`,
              borderWidth: 2,
            }
          );
        }
      }
    });
  }

  return [...locationData, ...comparisionData];
}

function addSpacing(label) {
  const spacingAmount = 20 - label.length;
  let spacing = " ";
  for (let i = 0; i < spacingAmount; i++) {
    spacing = spacing + ".";
  }
  return spacing;
}

function formatDayLengthLabel(tooltipItems, location) {
  const time = Number((tooltipItems.parsed.y * 60).toFixed(0));
  let minutes = time % 60;
  let hours = (time - minutes) / 60;
  let labelText = `${hours} h ${minutes} min`;

  if (tooltipItems.raw.polarNight) {
    labelText = `0 h (kaamos)`;
  }
  if (tooltipItems.dataset.data[tooltipItems.dataIndex].y === 24) {
    labelText = `24 h (yötön yö)`;
  }

  return ` ${tooltipItems.dataset.label}${addSpacing(
    tooltipItems.dataset.label
  )} ${labelText}`;
}

function formatSunTimesLabel(tooltipItems, location, comparisions) {
  let labelText = "";
  let currentDay = location.yearlySunTimes[tooltipItems.dataIndex];

  if (tooltipItems.datasetIndex !== 0) {
    const compa = comparisions.find(
      (item) => item.name === tooltipItems.dataset.label
    );
    currentDay = compa.yearlySunTimes[tooltipItems.dataIndex];
  }

  let sunrise = format(currentDay.sunrise.time, "HH:mm");
  let sunset = format(currentDay.sunset.time, "HH:mm");
  labelText = ` ↑ ${sunrise} ↓ ${sunset}`;

  if (currentDay.sunrise.polarNight || currentDay.sunset.polarNight) {
    labelText = `          Kaamos`;
  }

  if (currentDay.sunrise.midnightSun || currentDay.sunset.midnightSun) {
    labelText = ` Yötön yö`;
  }

  return ` ${tooltipItems.dataset.label}${addSpacing(
    tooltipItems.dataset.label
  )}${labelText}`;
}

export function getChartOptions(
  visibleChart,
  location,
  comparisions,
  currentDate,
  container
) {
  const todayCount = getDayOfYear(currentDate) - 1;
  const todayDate = format(currentDate, "dd.MM.");

  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    hover: {
      mode: "x",
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    datasets: {
      line: {
        pointRadius: 0,
        pointHoverRadius: 5,
        tension: 0.5,
      },
    },
    animation: {
      duration: 700,
    },
    animations: false,
    plugins: {
      chartAreaBackground: {
        fillColor: "rgba(0,40,100,0.4)",
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            scaleID: "x",
            value: todayCount,
            borderColor: "rgba(255, 255, 255, 0.4)",
            borderWidth: 1,
            label: {
              display: true,
              content: todayDate,
              font: { weight: "bold" },
              color: "#035",
              backgroundColor: "rgba(255,255,250,1)",
              borderRadius: 3,
              padding: 5,
              position: "center",
            },
          },
        },
      },
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems) => {
            return tooltipItems[0].label;
          },
          label: (tooltipItems) => {
            if (visibleChart === "dayLength") {
              return formatDayLengthLabel(tooltipItems, location);
            }
            return formatSunTimesLabel(tooltipItems, location, comparisions);
          },
        },

        backgroundColor: "rgba(0,50,100,0.95)",
        titleColor: "#fff",
        bodyColor: "#fff",
        borderWidth: 2,
        borderColor: "#09c",
        padding: 10,
        bodySpacing: 6,
        bodyFont: {
          family:
            'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono","Courier New", monospace',
          size: 12,
        },
        titleFont: {
          family:
            'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono","Courier New", monospace',
          size: 14,
        },

        filter: (tooltipItems) => {
          if (visibleChart === "sunTimes") {
            return tooltipItems.datasetIndex % 2 === 0;
          }
          return true;
        },
      },
    },

    scales: {
      x: {
        grid: {
          borderColor: "rgba(0,200,255,0.5)",
          borderWidth: 1,
          display: false,
          tickLength: 0,
          color: "red",
          drawOnChartArea: false,
        },

        ticks: {
          display: false,
          color: "rgba(0,200,255,0)",
          font: {
            weight: "bold",
          },
        },
      },
      y: {
        min: 0,
        max: 24,
        position: "left",
        grid: {
          borderColor: "rgba(0,200,255,0.5)",
          borderWidth: 0,
          color: "rgba(0,200,255,0.2)",
          tickLength: 0,
        },

        ticks: {
          display: true,
          stepSize: 4,
          padding: 0,
          mirror: true,
          labelOffset: 15,
          z: 1,

          callback: function (val) {
            if (visibleChart === "dayLength") {
              if (val === 0) {
                return null;
              }

              return `  ${val} h`;
            } else if (visibleChart === "sunTimes") {
              if (val === 0) {
                return null;
              }
              if (val < 10) {
                return "  0" + val + ":00  ";
              }
              return "  " + val + ":00  ";
            }
          },
          color: "rgba(0,200,255,0.6)",
          font: {
            weight: "bold",
            size: "12px",
          },
        },
      },
    },
  };
}
