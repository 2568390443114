import React from "react";

import Select, { createFilter } from "react-select";

import { useLocations } from "../../providers/LocationsProvider";
import { menuStyles } from "../../dropmenustyles";

import kunnat from "../../kunnat.json";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../providers/ThemeProvider";

export default function LocationSelectionMenu({ close }) {
  const { currentLocation } = useLocations();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const locations = kunnat.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  function selectLocation(selected) {
    navigate(`/${selected}`);
    close();
  }

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: false,
    matchFrom: "start",
  };

  const menuColor = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#004084" : "#0059a9",
      borderRadius: "10px",
      border: "none",
      padding: "0.3rem 0.1rem 0.3rem 0.7rem",
    }),
  };

  return (
    <div className="w-full text-gray-600">
      <Select
        name="location"
        id="location"
        options={locations}
        value={locations.find((item) => item.label === currentLocation.name)}
        onChange={(selected) => selectLocation(selected.value)}
        styles={{ ...menuStyles, ...menuColor }}
        filterOption={createFilter(filterConfig)}
        noOptionsMessage={() => "Ei tuloksia"}
        aria-label="Valitse paikkakunta"
      />
    </div>
  );
}
