import React, { useState, useEffect } from "react";

import Select from "react-select";

import { useLocations } from "../../providers/LocationsProvider";

import { menuStyles } from "../../dropmenustyles";
import { comparisionChartColors } from "../../config";
import { calculateDaylightInfo, calculateYearlySunTimes } from "../../utils";

import LineChart from "./LineChart";
import ComparisionSelection from "./ComparisionSelection";
import { useTheme } from "../../providers/ThemeProvider";

const chartOptions = [
  {
    value: "dayLength",
    label: "Päivän pituus",
  },
  {
    value: "sunTimes",
    label: "Auringonnousu ja -lasku",
  },
];

export default function ChartContainer({ toggleSearchDialogOpen }) {
  const { currentLocation, currentDate } = useLocations();
  const [comparisions, setComparisions] = useState([]);
  const [compColors, setCompColors] = useState([]);

  const [visibleChart, setVisibleChart] = useState("dayLength");

  const { theme } = useTheme();

  useEffect(() => {
    const compCol = comparisionChartColors.map((item) => ({
      ...item,
      usedBy: "",
    }));
    setCompColors(compCol);
  }, []);

  function addComparision(newComparision) {
    const daylightInfo = calculateDaylightInfo(newComparision, currentDate);
    const yearlySunTimes = calculateYearlySunTimes(newComparision, currentDate);

    const firstAvailableColor = compColors.findIndex((c) => !c.usedBy);
    const comp = {
      ...newComparision,
      daylightInfo,
      yearlySunTimes,
      color: compColors[firstAvailableColor],
    };

    const updatedComparisions = [...comparisions, comp];
    setComparisions(updatedComparisions);
    let updatedColors = [...compColors];

    updatedColors[firstAvailableColor].usedBy = newComparision.name;
    setCompColors(updatedColors);
  }

  function removeComparision(removedComparision) {
    const removedColorIndex = compColors.findIndex(
      (item) =>
        item.usedBy.toLowerCase() === removedComparision.name.toLowerCase()
    );

    let updatedColors = [...compColors];
    updatedColors[removedColorIndex].usedBy = "";
    setCompColors(updatedColors);

    const updatedComparisions = comparisions.filter(
      (item) => item.name !== removedComparision.name
    );
    setComparisions(updatedComparisions);
  }

  function resetComparisions() {
    setComparisions([]);
    const compCol = comparisionChartColors.map((item) => ({
      ...item,
      usedBy: "",
    }));
    setCompColors(compCol);
  }

  function toggleComparisionVisibility(toggledComparision) {
    const updatedComparisions = [...comparisions];

    let toggled = updatedComparisions.find(
      (item) => item.name === toggledComparision.name
    );
    toggled.hidden = toggled.hidden ? false : true;
    setComparisions([...updatedComparisions]);
  }
  const menuColor = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#004084" : "#0060aa",
      borderRadius: "10px",
      border: "none",
      padding: "0.3rem 0.1rem 0.3rem 0.7rem",
    }),
  };
  return (
    <div className="w-full mx-auto select-none">
      <div
        className="
          sm:flex sm:justify-between sm:items-end 
          mb-6 sm:pb-4 
          sm:border-b-[3px] border-dotted border-heading-light/50 dark:border-sky-600
        "
      >
        <h3
          className="
            mb-4 sm:mb-0 
            text-xl sm:text-2xl lg:text-3xl font-extrabold text-center whitespace-nowrap 
            text-heading-light 
            text-shadow-sm shadow-day-dark dark:shadow-night-darker
          "
        >
          Vuosi {currentDate.getFullYear()}
        </h3>

        <div className="text-gray-600 sm:w-64">
          <Select
            name="visibleChart"
            id="visibleChart"
            options={chartOptions}
            value={chartOptions.find((item) => item.value === visibleChart)}
            onChange={(selected) => setVisibleChart(selected.value)}
            styles={{ ...menuStyles, ...menuColor }}
            isSearchable={false}
            aria-label="Valitse kuvaaja"
          />
        </div>
      </div>

      <div className="relative w-full h-[400px] sm:h-[600px] overflow-auto">
        <LineChart
          currentLocation={currentLocation}
          comparisions={comparisions}
          visibleChart={visibleChart}
          currentDate={currentDate}
        />
      </div>
      <ComparisionSelection
        currentLocation={currentLocation}
        toggleSearchDialogOpen={toggleSearchDialogOpen}
        comparisions={comparisions}
        addComparision={addComparision}
        removeComparision={removeComparision}
        resetComparisions={resetComparisions}
        toggleComparisionVisibility={toggleComparisionVisibility}
      />
    </div>
  );
}
