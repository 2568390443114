import React, { useEffect, useState } from "react";
import { format, roundToNearestMinutes, startOfDay } from "date-fns";

import { fi } from "date-fns/locale/";

import { useLocations } from "../../providers/LocationsProvider";
import { formatDuration } from "../../utils";
import html2canvas from "html2canvas";
import { SunRiseIcon, SunSetIcon, DownloadIcon } from "../Common/Icons";

import logo from "../../assets/logo.png";
import Spinner from "../Common/Spinner";

const Button = ({ children, clicked, label }) => (
  <button
    className="
      flex items-center justify-center flex-shrink-0 
      w-10 h-10 sm:w-12 sm:h-12      
      rounded-full 
      bg-white
      shadow-xl
      hover:bg-gray-100
      transition-colors"
    onClick={clicked}
    aria-label={label}
  >
    {children}
  </button>
);

export default function ShareImageModal() {
  const { currentLocation, currentDate } = useLocations();

  const [isGenerating, setGenerating] = useState(false);
  const [base64String, setBase64String] = useState(null);

  useEffect(() => {
    const generateImage = async () => {
      const modalContentElement = await waitForElement("#modal-content");
      if (modalContentElement) {
        setGenerating(true);

        try {
          html2canvas(modalContentElement, {
            backgroundColor: null,
          })
            .then((canvas) => {
              const image = canvas.toDataURL();
              setBase64String(image);
            })
            .finally(() => {
              setGenerating(false);
            });
        } catch (error) {
          console.error("Error generating image", error);
        }
      }
    };

    generateImage();
  }, []);

  const waitForElement = (selector) => {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          clearInterval(intervalId);
          resolve(element);
        }
      }, 100);
    });
  };

  const downloadImage = () => {
    const downloadLink = document.createElement("a");
    const linkSource = base64String;

    downloadLink.href = linkSource;
    downloadLink.download = `PaivanPituus_${currentLocation.name}_${format(
      currentDate,
      "d.M.yyyy",
      {
        locale: fi,
      }
    )}.png`;
    downloadLink.click();
  };

  let sunriseFormatted = format(
    roundToNearestMinutes(currentLocation.daylightInfo.sunrise.time),
    "HH:mm"
  );
  let sunsetFormatted = format(
    roundToNearestMinutes(currentLocation.daylightInfo.sunset.time),
    "HH:mm"
  );

  function getSymbol(change) {
    if (Math.abs(change) < 30) {
      return "± ";
    }
    if (change < 0) {
      return "- ";
    } else return "+ ";
  }

  const changesContent = [
    { id: "day", label: "vrk" },
    { id: "week", label: "vk" },
    { id: "month", label: "kk" },
  ];

  const dayLengthHours = Math.floor(
    currentLocation.daylightInfo.dayLength / 60 / 60
  );
  const dayLengthMinutes =
    Math.round(currentLocation.daylightInfo.dayLength / 60) % 60;

  const isMidnightSun =
    startOfDay(currentDate) >= currentLocation.midnightSun.start &&
    startOfDay(currentDate) <= currentLocation.midnightSun.end;
  const isPolarNight =
    startOfDay(currentDate) >= currentLocation.polarNight.start &&
    startOfDay(currentDate) <= currentLocation.polarNight.end;

  if (isMidnightSun) {
    sunriseFormatted = format(currentLocation.midnightSun.start, "d.M.");
    sunsetFormatted = format(currentLocation.midnightSun.end, "d.M.");
  }

  if (isPolarNight) {
    sunriseFormatted = format(currentLocation.polarNight.end, "d.M.");
    sunsetFormatted = format(currentLocation.polarNight.start, "d.M.");
  }

  if (base64String) {
    return (
      <div
        className="
        flex flex-col items-center  
        w-full max-w-[600px]
        p-0 sm:p-1
        bg-white
        border-4 border-white 
        rounded-2xl sm:rounded-3xl"
      >
        <img
          src={base64String}
          alt={`Päivän pituus ${currentLocation.name_inflected} ${format(
            currentDate,
            "d.M.yyyy"
          )}`}
          className="w-full h-auto "
        />

        <div className="absolute -top-6 right-4">
          <Button clicked={downloadImage}>
            <DownloadIcon
              width="50%"
              height="50%"
              fill="none"
              stroke="#069"
              strokeWidth="3"
            />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className="
            flex justify-center items-center 
            w-20 h-20
             bg-white rounded-3xl            
            "
      >
        <div className="w-8 h-8 ">
          <Spinner color="#069" />
        </div>
      </div>
      <div
        style={{ position: "absolute", left: "2000px" }}
        className="flex flex-col items-center"
      >
        <div
          className="
            relative
            w-[420px] max-w-[500px]
            py-1
            rounded-2xl 
            bg-daybg bg-[length:800px_auto]
          "
          id="modal-content"
          style={{ backgroundPosition: "45% 15%" }}
        >
          <div
            className="
                flex items-end justify-between
                pt-2 pb-2 mx-4
                border-b-2 border-dotted border-heading-light/50 
                "
          >
            <span className="block text-3xl font-extrabold">
              {currentLocation.name}
            </span>
            <span
              className="
                  block 
                  text-lg font-bold text-heading-light
                  "
            >
              {format(currentDate, "d.M.yyyy", {
                locale: fi,
              })}
            </span>
          </div>

          <div
            className=" 
                flex items-center justify-between
                mx-4 py-2
              "
          >
            <div>
              <span className="text-sm text-heading-light tracking-wider uppercase">
                Päivän pituus tänään
              </span>
              {isMidnightSun || isPolarNight ? (
                <span
                  className="
                    block
                    py-2 
                    text-[54px] font-extrabold 
                    leading-none tracking-tight whitespace-nowrap
                  "
                >
                  {isMidnightSun ? "Yötön yö" : "Kaamos"}
                </span>
              ) : (
                <div className="flex items-center gap-2">
                  <span className="text-6xl font-extrabold leading-none">
                    {dayLengthHours}
                  </span>
                  <span className="text-3xl font-extrabold text-heading-light">
                    h
                  </span>
                  <span className="text-6xl font-extrabold leading-none">
                    {dayLengthMinutes}
                  </span>
                  <span className="text-3xl font-extrabold text-heading-light">
                    min
                  </span>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2 mt-2">
              <div className="flex items-center justify-between">
                {isPolarNight ? (
                  <SunSetIcon
                    width="28px"
                    height="28px"
                    stroke="yellow"
                    fill="yellow"
                  />
                ) : (
                  <SunRiseIcon
                    width="28px"
                    height="28px"
                    stroke="yellow"
                    fill="yellow"
                  />
                )}
                <span
                  className="
                      ml-2 
                      text-2xl font-bold leading-none  tracking-wide text-white
                    "
                >
                  {isPolarNight ? sunsetFormatted : sunriseFormatted}
                </span>
              </div>

              <div className="flex items-center justify-between">
                {isPolarNight ? (
                  <SunRiseIcon
                    width="28px"
                    height="28px"
                    stroke="yellow"
                    fill="yellow"
                  />
                ) : (
                  <SunSetIcon
                    width="28px"
                    height="28px"
                    stroke="yellow"
                    fill="yellow"
                  />
                )}
                <span
                  className="
                    ml-2 
                    text-2xl font-bold leading-none  tracking-wide text-white
                  "
                >
                  {isPolarNight ? sunriseFormatted : sunsetFormatted}
                </span>
              </div>
            </div>
          </div>

          <div
            className="
                flex justify-between items-center gap-2
                pb-2 pt-1 mx-4 mt-1
                text-sm font-bold  
            "
          >
            <span className="mr-2 auppercase">Muutos</span>
            {changesContent.map((item, idx) => {
              return (
                <div
                  className="flex items-center justify-between pb-0"
                  key={idx}
                >
                  <span className="block mr-2 leading-none text-heading-light">
                    {item.label}
                  </span>
                  <span className="whitespace-nowrap">
                    {getSymbol(currentLocation.dayLengthChanges[item.id])}
                    {formatDuration(
                      currentLocation.dayLengthChanges[item.id],
                      "seconds",
                      true
                    )}
                  </span>
                </div>
              );
            })}
          </div>

          <div
            className="
                  flex justify-between items-center
                  pb-[6px] mx-4 
                  border-t-2 border-dotted border-heading-light/50  
                "
          >
            <img
              className="w-[150px] h-[30px] mt-1 mr-4"
              src={logo}
              alt="PäivänPituus.fi"
            />{" "}
            <span
              className="
                  block 
                  text-sm font-sans font-bold tracking-tight
                 text-heading-light 
                 whitespace-nowrap
                 "
            >
              paivanpituus.fi/{currentLocation.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
