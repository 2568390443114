import React, { useContext, useState } from "react";
const ModalContext = React.createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const [modalContent, setModalContent] = useState(null);
  const openModal = setModalContent;

  const closeModal = React.useCallback(() => {
    setModalContent(null);
  }, [setModalContent]);

  const value = React.useMemo(
    () => ({ modalContent, openModal, closeModal }),
    [modalContent, openModal, closeModal]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
