import React from "react";
import { CalendarIcon } from "../Common/Icons";

const specialEventNotes = {
  marchEquinox: {
    note: "Tänään on kevätpäiväntasaus eli yö ja päivä ovat suunnilleen yhtä pitkiä kaikkialla maapallolla.",
    noteHtml: (
      <p>
        Tänään on{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Kevätpäiväntasaus"
        >
          kevätpäiväntasaus
        </a>{" "}
        eli yö ja päivä ovat suunnilleen yhtä pitkiä kaikkialla maapallolla.
      </p>
    ),
  },
  septemberEquinox: {
    note: "Tänään on syyspäiväntasaus eli yö ja päivä ovat suunnilleen yhtä pitkiä kaikkialla maapallolla.",
    noteHtml: (
      <p>
        Tänään on{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Syyspäiväntasaus"
        >
          syyspäiväntasaus
        </a>{" "}
        eli yö ja päivä ovat suunnilleen yhtä pitkiä kaikkialla maapallolla.
      </p>
    ),
  },
  juneSolstice: {
    note: "Pohjoisella pallonpuoliskolla on tänään kesäpäivänseisaus eli päivä on pisimmillään.",
    noteHtml: (
      <p>
        Pohjoisella pallonpuoliskolla on tänään{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Kesäpäivänseisaus"
        >
          kesäpäivänseisaus
        </a>{" "}
        eli päivä on pisimillään.
      </p>
    ),
  },
  decemberSolstice: {
    note: "Pohjoisella pallonpuoliskolla on tänään talvipäivänseisaus eli päivä on lyhimmillään.",
    noteHtml: (
      <p>
        Pohjoisella pallonpuoliskolla on tänään{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Talvipäivänseisaus"
        >
          talvipäivänseisaus
        </a>{" "}
        eli päivä on lyhimmillään.
      </p>
    ),
  },
  summerTime: {
    note: "Suomessa siirrytään tänään kesäaikaan eli kelloja siirretään tunnilla eteenpäin.",
    noteHtml: (
      <p>
        Suomessa siirrytään tänään{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Kesäaika"
        >
          kesäaikaan
        </a>{" "}
        eli kelloja siirretään tunnilla eteenpäin.
      </p>
    ),
  },
  winterTime: {
    note: "Suomessa siirrytään tänään normaaliaikaan eli kelloja siirretään tunnilla taaksepäin.",
    noteHtml: (
      <p>
        Suomessa siirrytään tänään{" "}
        <a
          style={{ color: "#f60" }}
          href="https://fi.wikipedia.org/wiki/Kesäaika"
        >
          normaaliaikaan
        </a>{" "}
        eli kelloja siirretään tunnilla taaksepäin.
      </p>
    ),
  },
};

export default function SpecialEventNote({ event }) {
  return (
    <div
      className="
      flex justify-center items-center 
      w-full 
      p-3 sm:p-4 m:0 sm:mb-4 
      bg-[#fde047] 
      text-sm sm:text-base font-sans font-bold
      text-gray-700 
    "
    >
      <div
        className="
        flex justify-center items-center 
        w-9 h-9 shrink-0 sm:w-11 sm:h-11 
        rounded-full 
        mr-3 
        bg-[#ff9900]
      "
      >
        <CalendarIcon width="60%" height="60%" stroke="#fff" fill="none" />
      </div>
      {specialEventNotes[event].noteHtml}
    </div>
  );
}
