import React from "react";
import { useTheme } from "../../providers/ThemeProvider";

export default function Divider() {
  const { theme } = useTheme();

  const fillColor = theme === "dark" ? "#005795" : "#00a8e7";

  return (
    <div
      className="
      relative 
      w-full h-[5vw] max-h-10 
      overflow-hidden
      bg-day-dark dark:bg-night-dark 
    "
    >
      <svg
        viewBox="0 0 1024 80"
        preserveAspectRatio="none"
        width="200%"
        height="100%"
        fill={fillColor}
        style={{
          position: "absolute",
          top: "0",
          left: "-10vw",
          marginTop: "-2px",
        }}
      >
        <path d="M 0 80 C 0 80 113.161301 6.757378 177.995911 8.031418 C 237.138504 9.193611 277.991974 80 337.145203 80 C 398.814545 80 443.00592 8.917732 504.670746 8.031418 C 570.791809 7.081009 620.728149 80 686.854797 80 C 750.427124 79.490219 797.092346 7.338425 860.662598 8.031418 C 921.06958 8.689926 1024 80 1024 80 L 1024 -8e-06 L 0 -8e-06 L 0 80 Z" />
      </svg>
    </div>
  );
}
