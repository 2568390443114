import { useMemo, useState } from "react";
import Select from "react-select";

import { utcToZonedTime } from "date-fns-tz";
import orderBy from "lodash/orderBy";

import { worldLocations, finLocations } from "../../config";
import {
  calculateLengthOfDay,
  calculateSunrise,
  calculateSunset,
} from "../../utils";

import { menuStyles } from "../../dropmenustyles";

import CityList from "./CityList";
import { differenceInSeconds, subDays } from "date-fns";
import { useLocations } from "../../providers/LocationsProvider";
import { useTheme } from "../../providers/ThemeProvider";

export default function CityListContainer({ region }) {
  const { currentDate } = useLocations();
  const [sortBy, setSortBy] = useState("name");

  const { theme } = useTheme();

  const sortOptions = [
    {
      value: "name",
      label: "A-Ö",
    },
    {
      value: "longest",
      label: "Pisin-lyhin",
    },
    {
      value: "shortest",
      label: "Lyhin-pisin",
    },
  ];

  let citylist = useMemo(() => {
    const listUsed = region === "fin" ? finLocations : worldLocations;

    return listUsed.map((item) => {
      const today = currentDate;

      let sunrise = calculateSunrise(item.latitude, item.longitude, today);
      let sunset = calculateSunset(item.latitude, item.longitude, today);

      sunrise.time = utcToZonedTime(sunrise.time, item.timeZone);
      sunset.time = utcToZonedTime(sunset.time, item.timeZone);

      const sunriseDay = sunrise.time.getDate();
      const sunriseMonth = sunrise.time.getMonth();
      sunset.time.setDate(sunriseDay);
      sunset.time.setMonth(sunriseMonth);

      const sunriseMinutes =
        sunrise.time.getHours() * 60 + sunrise.time.getMinutes();
      let sunrisePercentage = (sunriseMinutes / 1440) * 100;

      let sunsetMinutes =
        sunset.time.getHours() * 60 + sunset.time.getMinutes();

      if (
        sunset.time.getMonth() > 3 &&
        sunset.time.getMonth() < 8 &&
        sunsetMinutes < 100
      ) {
        sunsetMinutes = sunsetMinutes + 1440;
      }

      let sunsetPercentage = (sunsetMinutes / 1440) * 100;

      if (sunrise.midnightSun || sunset.midnightSun) {
        sunrisePercentage = 0;
        sunsetPercentage = 100;
      }

      if (sunrise.polarNight) {
        sunsetPercentage = 0;
      }

      let todayLength = differenceInSeconds(sunset.time, sunrise.time);

      if (todayLength < 0) {
        todayLength = todayLength + 1440 * 60;
      }
      if (sunrise.polarNight || sunset.polarNight) {
        todayLength = 0;
      }
      if (sunrise.midnightSun || sunset.midnightSun) {
        todayLength = 1440 * 60;
      }

      let yesterdayLength = calculateLengthOfDay(
        item.latitude,
        item.longitude,
        subDays(today, 1)
      );

      if (yesterdayLength < 0) {
        yesterdayLength = yesterdayLength + 1440 * 60;
      }

      let diffToYesterday = Math.round((todayLength - yesterdayLength) / 60);

      // if (Math.abs(diffToYesterday) > 30) {
      //   if (item.longitude > 0) {
      //     diffToYesterday = Math.abs(diffToYesterday) - 60;
      //   } else {
      //     diffToYesterday = 60 - Math.abs(diffToYesterday);
      //   }
      // }

      const localDate = utcToZonedTime(today, item.timeZone);

      let localHours = localDate.getHours();

      if (localHours > 24) {
        localHours = localHours - 24;
      }
      const currentMinutes = localHours * 60 + today.getMinutes();

      return {
        ...item,
        sunrise: sunrise.time,
        sunset: sunset.time,
        dayLength: todayLength,
        daylightArch: {
          start: sunrisePercentage,
          end: sunsetPercentage,
          archLength: sunsetPercentage - sunrisePercentage,
        },
        changeFromYesterday: diffToYesterday,
        currentTimeInMinutes: currentMinutes,
      };
    });
  }, [currentDate, region]);

  const sortAttribute = sortBy === "name" ? "name" : "dayLength";
  const listOrder = sortBy === "longest" ? "desc" : "asc";

  citylist = orderBy(citylist, [sortAttribute], [listOrder]);
  const menuColor = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#004084" : "#0060aa",
      borderRadius: "10px",
      border: "none",
      padding: "0.3rem 0.1rem 0.3rem 0.7rem",
    }),
  };
  return (
    <div className="w-full sm:pb-8">
      <div
        className="
        sm:flex sm:justify-between sm:items-end 
        mb-6 sm:pb-4       
        sm:border-b-[3px] border-dotted border-heading-light/50 dark:border-night-light  
      "
      >
        <h3
          className="
          mb-4 sm:mb-0
          text-xl sm:text-2xl font-extrabold whitespace-nowrap text-center 
          text-heading-light dark:text-heading-dark  
          text-shadow-sm shadow-day-dark dark:shadow-night-darker 
        "
        >
          {region === "fin"
            ? "Päivän pituus kotimaassa"
            : "Päivän pituus maailmalla"}
        </h3>
        <div className="text-gray-600 sm:w-56">
          <Select
            name="sortBy"
            id="sortBy"
            options={sortOptions}
            value={sortOptions.find((item) => item.value === sortBy)}
            onChange={(selected) => setSortBy(selected.value)}
            styles={{ ...menuStyles, ...menuColor }}
            isSearchable={false}
            aria-label="Järjestä"
          />
        </div>
      </div>
      <CityList cities={citylist} region={region} />
    </div>
  );
}
