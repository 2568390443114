import {
  getDayOfYear,
  startOfYear,
  endOfYear,
  subDays,
  addDays,
  eachDayOfInterval,
  differenceInSeconds,
  parseISO,
  differenceInDays,
  differenceInMinutes,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import kunnat from "./kunnat.json";

export const juneSolstice = {
  2022: "2022-06-21",
  2023: "2023-06-21",
  2024: "2024-06-20",
  2025: "2025-06-21",
  2026: "2026-06-21",
  2027: "2027-06-21",
  2028: "2028-06-20",
  2029: "2029-06-21",
  2030: "2030-06-21",
  2031: "2031-06-21",
  2032: "2032-06-21",
};
export const decemberSolstice = {
  2022: "2022-12-21",
  2023: "2023-12-22",
  2024: "2024-12-21",
  2025: "2025-12-21",
  2026: "2026-12-21",
  2027: "2027-12-22",
  2028: "2028-12-21",
  2029: "2029-12-21",
  2030: "2030-12-21",
  2031: "2031-12-22",
  2032: "2032-12-21",
};

// ===================== //
// ====== GENERAL ====== //
// ===================== //

export function parseUrl(str) {
  let parsed = str;
  parsed = parsed.replaceAll("%C3%A5", "å");
  parsed = parsed.replaceAll("%C3%A4", "ä");
  parsed = parsed.replaceAll("%C3%B6", "ö");
  parsed = parsed.replaceAll("%C3%85", "Å");
  parsed = parsed.replaceAll("%C3%84", "Ä");
  parsed = parsed.replaceAll("%C3%96", "Ö");
  parsed = parsed.replaceAll("%20", " ");
  return parsed;
}

export function testStorage(type) {
  try {
    let storage = window[type];
    let x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

export async function copyToClipboard(text) {
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
export function localizeDecimals(value) {
  const integer = `${value}`.split(".")[0];
  const decimals = `${value}`.split(".")[1];
  return `${integer},${decimals}`;
}

export function formatDuration(length, unit, abbreviate) {
  let lengthHours = Math.floor(Math.abs(length) / 60);
  if (unit === "seconds") {
    lengthHours = Math.floor(Math.abs(length) / 60 / 60);
  }
  let lengthMinutes = Math.abs(length) % 60;

  if (unit === "seconds") {
    lengthMinutes = Math.floor((Math.abs(length) / 60) % 60);
  }

  let unitHours = "tuntia";
  let unitMinutes = "minuuttia";

  if (abbreviate) {
    unitHours = "h";
    unitMinutes = "min";
  }

  let formatted = `${lengthMinutes} ${unitMinutes}`;

  if (lengthHours > 0) {
    formatted = `${lengthHours} ${unitHours} ${lengthMinutes} ${unitMinutes}`;
    if (lengthMinutes === 0 || lengthMinutes === 60) {
      formatted = `${lengthHours} ${unitHours}`;
    }
  }
  if (lengthHours === 0 && lengthMinutes === 60) {
    formatted = `1 ${unitHours}`;
  }

  return formatted;
}

// ========================= //
// ====== POSITIONING ====== //
// ========================= //

export function getGeoPosition() {
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
  };

  if (!navigator.geolocation) {
    return null;
  } else {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }
}

export function getNearest(latitude, longitude) {
  let nearest = null;

  kunnat.forEach((item) => {
    const distance = getDistance(
      latitude,
      longitude,
      item.latitude,
      item.longitude
    );

    if (!nearest || distance < nearest.distance) {
      nearest = {
        name: item.name,
        distance,
        latitude,
        longitude,
      };
    }
  });

  return nearest;
}

function getDistance(lat1, lon1, lat2, lon2) {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
}

// ====================== //
// ====== DAYLIGHT ====== //
// ====================== //

export function calculateSunrise(lat, long, date, sunrise = true) {
  const dayOfYear = getDayOfYear(date);

  const zenith = 90.83333333333333;
  const D2R = Math.PI / 180;
  const R2D = 180 / Math.PI;

  const lnHour = long / 15;
  let t;
  if (sunrise) {
    t = dayOfYear + (6 - lnHour) / 24;
  } else {
    t = dayOfYear + (18 - lnHour) / 24;
  }

  const M = 0.9856 * t - 3.289;

  let L =
    M + 1.916 * Math.sin(M * D2R) + 0.02 * Math.sin(2 * M * D2R) + 282.634;
  if (L > 360) {
    L = L - 360;
  } else if (L < 0) {
    L = L + 360;
  }

  let RA = R2D * Math.atan(0.91764 * Math.tan(L * D2R));
  if (RA > 360) {
    RA = RA - 360;
  } else if (RA < 0) {
    RA = RA + 360;
  }

  const Lquadrant = Math.floor(L / 90) * 90;
  const RAquadrant = Math.floor(RA / 90) * 90;
  RA = RA + (Lquadrant - RAquadrant);
  RA = RA / 15;

  const sinDec = 0.39782 * Math.sin(L * D2R);
  const cosDec = Math.cos(Math.asin(sinDec));

  let cosH =
    (Math.cos(zenith * D2R) - sinDec * Math.sin(lat * D2R)) /
    (cosDec * Math.cos(lat * D2R));

  let H;
  if (sunrise) {
    H = 360 - R2D * Math.acos(cosH);
  } else {
    H = R2D * Math.acos(cosH);
  }

  H = H / 15;
  const T = H + RA - 0.06571 * t - 6.622;

  let UTC = T - lnHour;
  if (UTC > 24) {
    UTC = UTC - 24;
  } else if (UTC < 0) {
    UTC = UTC + 24;
  }

  const ms = UTC * 60 * 60 * 1000;

  let formattedTime = new Date(ms);

  formattedTime.setUTCFullYear(date.getUTCFullYear());
  formattedTime.setUTCMonth(date.getUTCMonth());
  formattedTime.setUTCDate(date.getUTCDate());

  let polarNight = false;
  let midnightSun = false;

  if ((date.getMonth() < 3 || date.getMonth() > 8) && isNaN(ms)) {
    polarNight = true;
  }
  if (date.getMonth() > 3 && date.getMonth() < 9 && isNaN(ms)) {
    midnightSun = true;
  }
  // Adjust for DST
  // if (date.getTimezoneOffset() === -180 && !midnightSun) {
  //   formattedTime = addHours(formattedTime, 1);
  // }

  const timeObj = { time: formattedTime, midnightSun, polarNight };
  return timeObj;
}

export function calculateSunset(lat, long, date) {
  return calculateSunrise(lat, long, date, false);
}

export function calculateDaylightInfo(location, date) {
  const lat = location.latitude;
  const lon = location.longitude;

  let sunrise = calculateSunrise(lat, lon, date);
  let sunset = calculateSunset(lat, lon, date);

  const timeZone = location.timeZone ? location.timeZone : "Europe/Helsinki";

  sunrise.time = utcToZonedTime(sunrise.time, timeZone);
  sunset.time = utcToZonedTime(sunset.time, timeZone);

  if (sunrise.time.getDate() > date.getDate()) {
    sunrise.time = subDays(sunrise.time, 1);
  }

  if (sunset.time < sunrise.time) {
    sunset = calculateSunset(lat, lon, addDays(date, 1));
  }
  let todayLength = differenceInMinutes(sunset.time, sunrise.time) * 60; //calculateLengthOfDay(lat, lon, date, "seconds");
  return {
    sunrise,
    sunset,
    dayLength: todayLength,
  };
}

export function calculateDayLightChanges(
  location,
  daylightInfo,
  currentDate
  // polarNightRange,
  // midnightSunRange
) {
  // const currentDate = new Date();

  const lat = location.latitude;
  const lon = location.longitude;

  let todayLength =
    differenceInMinutes(daylightInfo.sunset.time, daylightInfo.sunrise.time) *
    60;

  const isPolarNightNow =
    daylightInfo.sunrise.polarNight || daylightInfo.sunset.polarNight;
  const isMidnightSunNow =
    daylightInfo.sunrise.midnightSun || daylightInfo.sunset.midnightSun;

  if (isMidnightSunNow) {
    todayLength = 24 * 60 * 60;
  }
  if (isPolarNightNow) {
    todayLength = 0;
  }
  let diffToYesterday =
    todayLength - calculateLengthOfDay(lat, lon, subDays(currentDate, 1));

  let diffLastWeek =
    todayLength - calculateLengthOfDay(lat, lon, subDays(currentDate, 7));

  let diffLastMonth =
    todayLength - calculateLengthOfDay(lat, lon, subDays(currentDate, 30));

  let year = currentDate.getFullYear();
  if (year > 2032) {
    year = 2023;
  }
  let diffToShortest;

  if (isPolarNightNow) {
    diffToShortest = 0;
  } else if (isMidnightSunNow) {
    diffToShortest = 24 * 60 * 60;
  } else {
    diffToShortest =
      todayLength -
      calculateLengthOfDay(
        lat,
        lon,
        new Date(decemberSolstice[year]),
        "seconds"
      );
  }

  let diffToLongest;

  if (isPolarNightNow) {
    diffToLongest = 24 * 60 * 60;
  } else if (isMidnightSunNow) {
    diffToLongest = 0;
  } else {
    diffToLongest =
      todayLength -
      calculateLengthOfDay(lat, lon, new Date(juneSolstice[year]), "seconds");
  }

  return {
    day: diffToYesterday,
    week: diffLastWeek,
    month: diffLastMonth,
    min: diffToShortest,
    max: diffToLongest,
  };
}

export function calculateLengthOfDay(latitude, longitude, currentDate) {
  const sunrise = calculateSunrise(latitude, longitude, currentDate);
  let sunset = calculateSunset(latitude, longitude, currentDate);

  // const timeZone = location.timeZone ? location.timeZone : "Europe/Helsinki";

  // sunrise.time = utcToZonedTime(sunrise.time, timeZone);
  // sunset.time = utcToZonedTime(sunset.time, timeZone);

  if (sunset.time < sunrise.time) {
    sunset = calculateSunset(latitude, longitude, addDays(currentDate, 1));
  }

  let dayLength = differenceInSeconds(sunset.time, sunrise.time);

  if (sunrise.midnightSun || sunset.midnightSun) {
    dayLength = 24 * 60 * 60;
  }

  if (sunrise.polarNight || sunset.polarNight) {
    dayLength = 0;
  }

  return dayLength;
}

export function calculateYearlySunTimes(location, currentDate) {
  const latitude = location.latitude;
  const longitude = location.longitude;

  let start = startOfYear(currentDate);
  let end = endOfYear(currentDate);

  const days = eachDayOfInterval({
    start,
    end,
  });

  const sunTimes = days.map((date) => {
    let sunrise = calculateSunrise(latitude, longitude, date);
    let sunset = calculateSunset(latitude, longitude, date);

    const timeZone = location.timeZone ? location.timeZone : "Europe/Helsinki";

    sunrise.time = utcToZonedTime(sunrise.time, timeZone);
    sunset.time = utcToZonedTime(sunset.time, timeZone);

    return { date, sunrise, sunset };
  });

  return sunTimes;
}

export function calculateSpecialEvents(location, currentDate, event) {
  if (
    (event === "polarNight" && location.latitude < 67) ||
    (event === "midnightSun" && location.latitude < 65)
  ) {
    return { start: null, end: null };
  }

  let start, end;

  let year = currentDate.getFullYear();

  if (event === "midnightSun") {
    start = new Date(`${year}-05-15`);
    end = new Date(`${year}-07-31`);
  } else {
    start =
      currentDate.getMonth() === 0
        ? new Date(`${year - 1}-11-20`)
        : new Date(`${year}-11-20`);
    end =
      currentDate.getMonth() === 0
        ? new Date(`${year}-01-20`)
        : new Date(`${year + 1}-01-20`);
  }

  const days = eachDayOfInterval({
    start,
    end,
  });

  let eventDays = [];

  days.forEach((day) => {
    const sunrise = calculateSunrise(
      location.latitude,
      location.longitude,
      day
    );
    const sunset = calculateSunset(location.latitude, location.longitude, day);

    if (sunrise[event] || sunset[event]) {
      eventDays.push(day);
    }
  });

  return { start: eventDays[0], end: eventDays[eventDays.length - 1] };
}

export function calculateDaysSinceEvent(currentDate, event) {
  let winterSol = parseISO(decemberSolstice[currentDate.getFullYear()]);

  if (event === "min") {
    if (
      getDayOfYear(currentDate) <
      getDayOfYear(parseISO(juneSolstice[currentDate.getFullYear()]))
    ) {
      winterSol = parseISO(decemberSolstice[currentDate.getFullYear() - 1]);
    }

    return differenceInDays(currentDate, winterSol);
  }

  let summerSol = parseISO(juneSolstice[currentDate.getFullYear()]);

  if (getDayOfYear(currentDate) >= getDayOfYear(winterSol)) {
    summerSol = parseISO(juneSolstice[currentDate.getFullYear() + 1]);
  }

  return differenceInDays(currentDate, summerSol);
}

// ======================== //
// ====== MOON PHASE ====== //
// ======================== //

function getJulianDate(date) {
  const time = date.getTime();
  const tzoffset = date.getTimezoneOffset();

  return time / 86400000 - tzoffset / 1440 + 2440587.5;
}

function normalize(value) {
  value = value - Math.floor(value);
  if (value < 0) value = value + 1;
  return value;
}

export function getMoonPhase(date) {
  const jd = getJulianDate(date);

  const LUNAR_MONTH = 29.530588853;

  const lunarAgePercent = normalize((jd - 2451550.1) / LUNAR_MONTH);
  const lunarAge = lunarAgePercent * LUNAR_MONTH;

  if (lunarAge < 1.84566) return { offset: 0, name: "Uusikuu" };
  else if (lunarAge < 5.53699) return { offset: 1, name: "Kasvava" };
  else if (lunarAge < 9.22831) return { offset: 2, name: "Kasvava" };
  else if (lunarAge < 12.91963) return { offset: 3, name: "Kasvava" };
  else if (lunarAge < 16.61096) return { offset: 4, name: "Täysikuu" };
  else if (lunarAge < 20.30228) return { offset: 5, name: "Vähenevä" };
  else if (lunarAge < 23.99361) return { offset: 6, name: "Vähenevä" };
  else if (lunarAge < 27.68493) return { offset: 7, name: "Vähenevä" };
  return { offset: 8, name: "Vähenevä" };
}
