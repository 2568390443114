import React from "react";

export default function InfoModal() {
  return (
    <div className="w-full sm:w-[500px] p-6 xs:p-8 bg-day-dark rounded-2xl ">
      <p className=" font-sans font-semibold   mb-6">
        PäivänPituus.fi on epätieteellinen palvelu, jota ylläpidetään
        harrastelijapohjalta. Tiedoissa voi siis olla virheitä. Ilmoittele
        epäkohdista (ja mahdollisista kehitysideoista) palautelomakkeella.
      </p>

      <ul className="list-disc ml-4 text-sm font-sans font-semibold ">
        <li>
          Algoritmi auringon vaiheiden laskemiseen:{" "}
          <a
            className="text-heading-light "
            href="http://edwilliams.org/sunrise_sunset_algorithm.htm"
          >
            USNO
          </a>
        </li>
        <li>
          Paikkakuntien koordinaatit:{" "}
          <a
            className="text-heading-light"
            href="https://fi.wikipedia.org/wiki/Luettelo_Suomen_kuntien_koordinaateista"
          >
            Wikipedia
          </a>
          ,{" "}
          <a
            className="text-heading-light"
            href="https://gml.noaa.gov/grad/solcalc/"
          >
            NOAA
          </a>
        </li>
        <li>
          Ikonit:{" "}
          <a className="text-heading-light" href="https://fearthericons.com">
            Feather Icons{" "}
          </a>
        </li>{" "}
      </ul>
    </div>
  );
}
