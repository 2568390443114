import React from "react";

import { format, roundToNearestMinutes } from "date-fns";
import { useTheme } from "../../providers/ThemeProvider";

import { formatDuration } from "../../utils";
import { SunRiseIcon, SunSetIcon } from "../Common/Icons";
import { gradientColors } from "../../config";
import MapModal from "../Modals/MapModal";
import { useModal } from "../../providers/ModalProvider";

export default function CityCard({
  city,
  daylightArch,
  localTimeInMinutes,
  region,
}) {
  const { theme } = useTheme();
  const { openModal } = useModal();

  let length = formatDuration(city.dayLength, "seconds", true);
  let sunriseValue = format(roundToNearestMinutes(city.sunrise), "HH:mm");
  let sunsetValue = format(roundToNearestMinutes(city.sunset), "HH:mm");

  const isMidnightSun = daylightArch.archLength >= 100;
  const isPolarNight = daylightArch.archLength <= 0;
  const sunsetsAfterMidnight = daylightArch.end > 100;
  const isReallyShortDay = daylightArch.archLength < 20;

  const { archLength, start, end } = daylightArch;
  const { dawnYellow, dayYellow, duskBlue, nightBlue } = gradientColors[theme];

  let daylightGradient = `linear-gradient(to right, ${nightBlue}  ${
    start - Math.max((100 - archLength) / 10, 7)
  }%, ${dawnYellow} ${start}%, ${dayYellow} ${
    start + Math.max((100 - archLength) / 10, 7)
  }% ${Math.min(
    end - Math.max((100 - archLength) / 10, 7),
    90
  )}%,${dawnYellow} ${end}%, ${nightBlue} ${Math.min(
    end + Math.max((100 - archLength) / 10, 7),
    100
  )}%)`;

  if (isReallyShortDay) {
    daylightGradient = `linear-gradient(to right, ${nightBlue} ${
      start - 8
    }%, ${dawnYellow} ${start}%, ${dayYellow} ${start + 5}% ${
      end - 5
    }%,${dawnYellow} ${end}%, ${nightBlue} ${end + 8}%)`;
  }

  if (sunsetsAfterMidnight) {
    daylightGradient = `linear-gradient(to right,
        ${dawnYellow} ${end - 100}% ,
        ${nightBlue}  ,
        ${dawnYellow}  ${start}% ,
        ${dayYellow} ${start + 15}% 85%,
      ${dawnYellow} 100%
      )`;
  }

  if (isPolarNight) {
    daylightGradient = `linear-gradient(to right, ${nightBlue} 25%, ${duskBlue}, ${nightBlue} 75%)`;
  }
  if (isMidnightSun) {
    daylightGradient = `linear-gradient(to right,${dawnYellow},${dayYellow},${dayYellow} ,${dawnYellow})`;
  }

  if (archLength >= 100) {
    length = "Yötön yö";
    sunriseValue = "--:--";
    sunsetValue = "--:--";
  }
  if (archLength <= 0) {
    length = "Kaamos";
    sunriseValue = "--:--";
    sunsetValue = "--:--";
  }

  let changeSymbol = "";
  if (city.changeFromYesterday > 0) {
    changeSymbol = "+";
  }
  if (city.changeFromYesterday === 0) {
    changeSymbol = "±";
  }

  return (
    <li
      className="
        flex flex-1 items-center 
        xs:min-w-[350px]
        py-3 px-4 
        rounded-xl 
        bg-day-darker/30 dark:bg-day-dark/20 
        hover:bg-day-darker/40 hover:dark:bg-day-dark/30 
        transition-colors
        cursor-pointer
      "
      key={city.name}
      onClick={() =>
        openModal(
          <MapModal
            region={region}
            name={city.name}
            latitude={city.latitude}
            longitude={city.longitude}
          />
        )
      }
    >
      <div className="flex flex-col w-full">
        <div
          className="
          flex justify-between items-center flex-wrap 
          pb-3 mb-3 
          border-b-2 border-dotted border-day-light dark:border-day-dark
        "
        >
          <div
            className="
              block 
              text-xl font-extrabold tracking-tight no-underline whitespace-nowrap 
              text-heading-light dark:text-heading-dark 
              text-shadow-sm shadow-day-dark dark:shadow-day-dark/30 

            "
          >
            {city.name}
          </div>

          <div className="flex flex-col w-32 min-w-[128px] sm:w-36 sm:min-w-[144px]">
            <div className="flex justify-between items-center mb-1">
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[9px] text-heading-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
              <div className="text-[6px] text-day-light">|</div>
            </div>

            <div
              className="
                relative 
                w-full h-3 
                border border-day-light dark:border-day-dark 
                rounded-full
              "
              style={{
                background: daylightGradient,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-2px",
                  left: (localTimeInMinutes / 1440) * 100 + "%",
                  width: "3px",
                  height: "14px",
                  backgroundColor: "#fff",
                  borderRadius: "9999px",
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center xs:flex-wrap">
          <div className="flex items-center">
            <span
              className="
              block 
              text-center sm:text-xl font-extrabold whitespace-nowrap tracking-tight 
              text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
            "
            >
              {length}
            </span>
            <span
              className="
              block 
              ml-2 
              text-center text-xs xs:text-sm whitespace-nowrap tracking-tight 
              text-heading-light dark:text-heading-dark 
              text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
            "
            >{`${changeSymbol} ${city.changeFromYesterday} min`}</span>
          </div>

          <div className="flex items-center ml-auto">
            <div className="flex items-end  ">
              <SunRiseIcon
                width="20px"
                height="20px"
                stroke="yellow"
                fill="yellow"
              />
              <span
                className="
                mx-2 
                text-lg leading-none 
                text-heading-light dark:text-heading-dark
                text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
              "
              >
                {sunriseValue}
              </span>
            </div>

            <div className="flex items-end">
              <SunSetIcon
                width="20px"
                height="20px"
                stroke="yellow"
                fill="yellow"
              />
              <span
                className="
                ml-2 
                text-lg leading-none 
                text-heading-light dark:text-heading-dark
                text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
              "
              >
                {sunsetValue}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
