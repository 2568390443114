import { useMemo } from "react";

function measureText(text, font) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  ctx.font = font;
  return ctx.measureText(text);
}

export default function useFontScaling(text, desiredWidth, font) {
  const sizedFont = useMemo(() => {
    const metrics = measureText(text, `50px ${font}`);

    if (!metrics) return "";
    const width =
      Math.abs(metrics.actualBoundingBoxLeft) +
      Math.abs(metrics.actualBoundingBoxRight);

    const newFontSize = (desiredWidth / width) * 50;

    return newFontSize;
  }, [text, desiredWidth, font]);

  return sizedFont;
}
