import React from "react";

import Select, { createFilter } from "react-select";

import { useLocations } from "../../providers/LocationsProvider";
import { menuStyles } from "../../dropmenustyles";

import kunnat from "../../kunnat.json";
import { useTheme } from "../../providers/ThemeProvider";

export default function ComparisionSelectionMenu({
  handleSetLocation,
  comparisionNames,
  selectedComparision,
}) {
  const { currentLocation } = useLocations();
  const { theme } = useTheme();

  let locationList = kunnat.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  locationList = locationList.filter(
    (item) =>
      !comparisionNames.includes(item.value) &&
      item.value !== currentLocation.name
  );

  function handleSelectLocation(selected) {
    const loc = kunnat.find((item) => item.name === selected);
    handleSetLocation(loc);
  }

  const filterConfig = {
    ignoreCase: true,
    matchFrom: "start",
  };

  const menuColors = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#003074aa" : "#0060aaaa",
      borderRadius: "12px",
      border: "none",
      padding: "0.6rem 0.4rem 0.6rem 1rem",
      "@media only screen and (max-width: 400px)": {
        padding: "0.4rem 0.2rem 0.4rem 0.8rem",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#6df",
      };
    },
  };

  return (
    <div className="w-full max-w-sm text-gray-600 ">
      <Select
        name="comparision-selection"
        id="comparision-selection"
        options={locationList}
        value={
          selectedComparision
            ? locationList.find(
                (item) => item.label === selectedComparision.name
              )
            : null
        }
        onChange={(selected) => handleSelectLocation(selected.value)}
        placeholder="Valitse kunta"
        styles={{ ...menuStyles, ...menuColors }}
        isSearchable={false}
        filterOption={createFilter(filterConfig)}
        noOptionsMessage={() => "Ei tuloksia"}
        aria-label="Valitse vertailukunta"
      />
    </div>
  );
}
