import { startOfDay } from "date-fns";
import React, { useEffect, useContext, useState } from "react";
import { useLocations } from "./LocationsProvider";

const ThemeContext = React.createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState();

  const { currentLocation, currentDate } = useLocations();

  useEffect(() => {
    if (currentLocation) {
      const isDayTime =
        currentDate > currentLocation.daylightInfo.sunrise.time &&
        currentDate < currentLocation.daylightInfo.sunset.time;
      const isPolarNight =
        startOfDay(currentDate) >= currentLocation.polarNight.start &&
        startOfDay(currentDate) <= currentLocation.polarNight.end;
      const isMidnightSun =
        startOfDay(currentDate) >= currentLocation.midnightSun.start &&
        startOfDay(currentDate) <= currentLocation.midnightSun.end;

      if ((!isDayTime || isPolarNight) && !isMidnightSun) {
        document.documentElement.classList.toggle("dark", true);
        setTheme("dark");
      } else {
        document.documentElement.classList.toggle("dark", false);
        setTheme("light");
      }
    }
  }, [currentDate, currentLocation]);

  const mode = React.useMemo(() => ({ theme }), [theme]);

  return !theme ? null : (
    <ThemeContext.Provider value={{ theme: mode.theme }}>
      {children}
    </ThemeContext.Provider>
  );
}
