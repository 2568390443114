import React, { useState, useEffect } from "react";
import { gradientColors } from "../../config";
import useInterval from "../../hooks/useInterval";
import { useTheme } from "../../providers/ThemeProvider";

export default React.memo(function SunDialGradient({ daylightArch }) {
  const { theme } = useTheme();

  const [archLength, setArchLength] = useState(0);
  const [archLengthTarget, setArchLengthTarget] = useState(0);

  const { dawnYellow, dayYellow, duskBlue, nightBlue } = gradientColors[theme];

  useEffect(() => {
    if (daylightArch.changeArchLength !== -1) {
      setArchLength(daylightArch.archLength);
      setArchLengthTarget(daylightArch.changeArchLength);
    } else {
      setArchLengthTarget(daylightArch.archLength);
    }
  }, [daylightArch]);

  let intervalDelay = archLength < archLengthTarget ? 5 : null;

  if (
    daylightArch.changeArchLength !== -1 &&
    daylightArch.changeArchLength < daylightArch.archLength
  ) {
    intervalDelay = archLength > archLengthTarget ? 5 : null;
  }
  if (
    daylightArch.changeArchLength === -1 &&
    archLength > daylightArch.archLength
  ) {
    intervalDelay = archLength > daylightArch.archLength ? 5 : null;
  }

  useInterval(() => {
    setArchLength(
      archLength > archLengthTarget ? archLength - 1 : archLength + 1
    );
  }, intervalDelay);

  const isMidnightSun = archLength >= 100;
  const isPolarNight = archLength <= 0;
  const isReallyLongDay = archLength > 90;
  const isReallyShortDay = archLength < 20;

  let daylightGradient = `conic-gradient(
    ${nightBlue} ${Math.max(50 - archLength / 2 - 7, 0)}%,
     ${duskBlue} ${Math.max(50 - archLength / 2 - 3, 1)}%,
     ${dawnYellow} ${Math.max(50 - archLength / 2 + 2, 3)}% ,
     ${dayYellow} ${
    50 - archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)
  }% ${50 + archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)}%,
   ${dawnYellow} ${Math.min(50 + archLength / 2 - 2, 97)}%,

     ${duskBlue} ${Math.min(50 + archLength / 2 + 3, 99)}% ,
    ${nightBlue} ${Math.min(50 + archLength / 2 + 7, 100)}%  
   )`;

  if (isPolarNight) {
    daylightGradient = `conic-gradient(${nightBlue},${nightBlue} )`;
  } else if (isMidnightSun) {
    daylightGradient = `conic-gradient(${dawnYellow},${dayYellow},${dayYellow},${dayYellow},${dawnYellow})`;
  } else if (isReallyLongDay) {
    daylightGradient = `conic-gradient(
       ${nightBlue} ${Math.max(50 - archLength / 2 - 2, 0)}%,
        ${dawnYellow} ${Math.max(50 - archLength / 2 + 2, 3)}% ,
        ${dayYellow} ${
      50 - archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)
    }% ${50 + archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)}%,
      ${dawnYellow} ${Math.min(50 + archLength / 2 - 2, 97)}%,

       ${nightBlue} ${Math.min(50 + archLength / 2 + 2, 100)}% 
      )`;
  } else if (isReallyShortDay) {
    daylightGradient = `conic-gradient(
        ${nightBlue} ${Math.max(50 - archLength / 2 - 7, 0)}%,
        ${duskBlue} ${Math.max(50 - archLength / 2 - 3, 1)}%,
        ${dawnYellow} ${Math.max(50 - archLength / 2, 3)}% ,
        ${dayYellow} ${
      50 - archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)
    }% ${50 + archLength / Math.min(Math.max((100 - archLength) / 10, 4), 5)}%,
      ${dawnYellow} ${Math.min(50 + archLength / 2, 97)}%,

        ${duskBlue} ${Math.min(50 + archLength / 2 + 3, 99)}% ,
       ${nightBlue} ${Math.min(50 + archLength / 2 + 7, 100)}%  
      )`;
  }

  const mask = `radial-gradient(
    closest-side,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 88%,
    rgba(0, 0, 0, 1) 88.5%,
    rgba(0, 0, 0, 1) 99.5%,
    rgba(0, 0, 0, 0) 100%
  )`;

  return daylightArch ? (
    <div
      style={{
        position: "absolute",
        background: daylightGradient,
        WebkitMaskImage: mask,
        maskImage: mask,
        transform: `rotate(${daylightArch.rotation + 0.5}turn)`,
      }}
      className="
        absolute 
        top-0 left-0 
        w-full h-full 
        rounded-full 
        pointer-events-none 
        bg-black
      "
    />
  ) : null;
});
