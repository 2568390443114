import React from "react";
import { motion } from "framer-motion";
import { CloseIcon } from "../Common/Icons";

import ModalBackdrop from "./ModalBackdrop";
import ModalPortal from "./ModalPortal";

const dropIn = {
  hidden: { opacity: 0, scale: 0.95, y: -10 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: { opacity: 0, scale: 0.95, y: 20, transition: { duration: 0.3 } },
};

export default function Modal({ close, children }) {
  return (
    <ModalPortal>
      <ModalBackdrop close={close}>
        <motion.div
          className="
            relative 
            flex justify-center items-center
            w-11/12 sm:w-auto 
            m-auto 
            rounded-2xl 
           shadow-xxl shadow-bg-day-darker
          "
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          key="content"
        >
          <button
            className="
              hidden 
              absolute 
              top-3 right-2 
              cursor-pointer
            "
            onClick={close}
          >
            <CloseIcon width="24px" height="24px" stroke="#6ce" fill="none" />
          </button>
          {children}
        </motion.div>
      </ModalBackdrop>
    </ModalPortal>
  );
}
