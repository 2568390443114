import React, { useRef, useEffect, useMemo } from "react";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { getChartData, getChartOptions } from "./chart-utils";

Chart.register(...registerables, annotationPlugin);

export default function LineChart({
  currentLocation,
  comparisions,
  visibleChart,
  currentDate,
  container,
}) {
  const chartRef = useRef(null);

  const chartConfig = useMemo(() => {
    const chartAreaBackground = {
      id: "chartAreaBackground",

      beforeDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, top, width, height },
        } = chart;
        ctx.save();
        ctx.fillStyle = options.fillColor;
        ctx.fillRect(left, top, width, height);
        ctx.restore();
      },
    };

    return {
      type: "line",
      data: {
        labels: [],
        datasets: getChartData(visibleChart, currentLocation, comparisions),
      },
      plugins: [chartAreaBackground, annotationPlugin],
      options: getChartOptions(
        visibleChart,
        currentLocation,
        comparisions,
        currentDate,
        container
      ),
    };
  }, [visibleChart, currentLocation, comparisions, currentDate, container]);

  useEffect(() => {
    const chartCanvas = chartRef.current;
    if (!chartCanvas) {
      return;
    }

    const chartInstance = new Chart(chartRef.current, chartConfig);

    return () => {
      chartInstance.destroy();
    };
  }, [currentLocation, comparisions, visibleChart, chartConfig]);

  return (
    <canvas
      ref={chartRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    ></canvas>
  );
}
