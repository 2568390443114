import { useState, useEffect, useCallback } from "react";

export default function useWindowWidth() {
  const [width, setWidth] = useState(undefined);
  const update = useCallback(() => setWidth(window.innerWidth), []);
  useEffect(() => {
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  });
  return width;
}
