import React, { useState } from "react";

import ComparisionSelectionMenu from "./ComparisionSelectionMenu";
import { AddIcon, CloseIcon, ResetIcon, TrashIcon } from "../Common/Icons";

export default function ComparisionSelection({
  currentLocation,
  comparisions,
  addComparision,
  removeComparision,
  toggleComparisionVisibility,
  resetComparisions,
}) {
  const [selectedComparision, setSelectedComparision] = useState(null);

  const comparisionNames = [];

  const comparisionButtons = comparisions.map((item) => {
    comparisionNames.push(item.name);

    return (
      <div
        className="
          flex justify-center items-center flex-1 
          md:max-w-[400px]
          pl-4 xs:pl-5 pr-3 xs:pr-4 py-3 xs:py-4 
          rounded-full 
          bg-day-darker/50 dark:bg-night-dark/50 
          hover:bg-day-darker/75 dark:hover:bg-night-dark
          transition-colors
          cursor-pointer
        "
        key={item.name}
        onClick={() => toggleComparisionVisibility(item)}
        style={{
          opacity: item.hidden ? "0.5" : "1",
        }}
      >
        <div
          className="w-[12px] h-[12px] rounded-full mr-3"
          style={{ backgroundColor: `rgba(${item.color.rgba},1)` }}
        />
        <span
          className="
            mr-6 
            text-sm sm:text-base 
            text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
          "
          style={{
            textDecoration: item.hidden ? "line-through" : "none",
          }}
        >
          {item.name}
        </span>

        <button
          className="
            flex justify-center items-center 
            w-5 h-5 ml-auto 
            rounded-full 
            bg-day-darker/75 dark:bg-day-light/20
            hover:bg-day-darker dark:hover:bg-day-light/50
            transition-colors
          "
          onClick={(e) => {
            e.stopPropagation();
            removeComparision(item);
          }}
        >
          <CloseIcon
            width="10px"
            height="10px"
            stroke="#3cf"
            strokeWidth="5px"
          />
        </button>
      </div>
    );
  });

  function handleAddComparision() {
    addComparision(selectedComparision);
    setSelectedComparision(null);
  }

  return (
    <div className="flex flex-row items-center flex-wrap gap-3 xs:gap-4 pt-4 ">
      <div
        className="
          flex justify-center items-center gap-4 
          w-full sm:w-auto min-w-[250px] md:max-w-[400px]
          px-4 py-4 mb-2 xs:mb-0 
          rounded-xl 
          bg-day-darker/30 dark:bg-day-dark/20  
        "
        type="location"
      >
        <div
          className="w-[12px] h-[12px] rounded-full "
          style={{ backgroundColor: "rgba(51,224,255,1)" }}
        />
        <span
          className="
            text-sm sm:text-base 
            text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
          "
        >
          {currentLocation.name}
        </span>
        <div className="w-[38px] ml-auto lg:w-4" />
      </div>
      {comparisionButtons}
      {comparisionButtons.length > 0 && (
        <button
          className="
            flex justify-center items-center shrink-0 
            w-11 h-11 sm:w-12 sm:h-12 
            ml-auto sm:ml-auto 
            rounded-full 
            bg-[#e35478] hover:bg-[#f35488] 
            transition-colors  
            disabled:opacity-30 disabled:pointer-events-none
          "
          onClick={resetComparisions}
        >
          <TrashIcon width="24px" height="24px" stroke="#fff" fill="none" />
        </button>
      )}
      {comparisionButtons.length < 5 && (
        <div
          className="
          flex items-center 
          w-full 
          mt-2 py-5 
          border-t-[3px] border-dotted border-day-dark/50 
        "
        >
          <ComparisionSelectionMenu
            handleSetLocation={setSelectedComparision}
            selectedComparision={selectedComparision}
            comparisionNames={comparisionNames}
          />

          <button
            className="
              flex justify-center items-center shrink-0
              w-11 h-11 sm:w-auto sm:h-auto 
              sm:py-4 sm:px-8 ml-4 
              rounded-full 
              hover:bg-[#2ad9f5] bg-[#2ac9e5] 
              transition-colors  
              disabled:hidden disabled:pointer-events-none
              plausible-event-name=Compare"
            onClick={handleAddComparision}
            disabled={!selectedComparision}
          >
            <div className="sm:hidden">
              <AddIcon
                width="18px"
                height="18px"
                stroke="#fff"
                fill="none"
                strokeWidth="4px"
              />
            </div>
            <span className="hidden sm:block whitespace-nowrap ml-2 sm:ml-0">
              Lisää vertailuun
            </span>
          </button>
        </div>
      )}
    </div>
  );
}
