import React from "react";

import { format } from "date-fns";
import { fi } from "date-fns/locale/";

import { useLocations } from "/src/providers/LocationsProvider";
import { useModal } from "/src/providers/ModalProvider";

import useWindowWidth from "/src/hooks/useWindowWidth";
import useFontScaling from "/src/hooks/useFontScaling";

import { localizeDecimals } from "/src/utils";
import { devices } from "/src/breakpoints";

import MapModal from "../Modals/MapModal";
import { MapIcon, MapPinIcon } from "/src/components/Common/Icons";
import Spinner from "/src/components/Common/Spinner";

const Button = ({ children, clicked, label, plEvent }) => (
  <button
    className={`
      flex items-center justify-center 
      w-8 h-8 xs:w-10 xs:h-10 
      p-0 
      rounded-full 
      bg-day-darker dark:bg-night-dark 
      hover:bg-night-dark/70 dark:hover:bg-night-darker 
      transition-colors 
      cursor-pointer
      plausible-event-name=${plEvent}`}
    onClick={clicked}
    aria-label={label}
  >
    {children}
  </button>
);

export default function LocationInfo() {
  const { currentLocation, currentDate, findGeoLocation, positioningStatus } =
    useLocations();

  const { openModal } = useModal();

  const innerWidth = useWindowWidth();

  let maxWidth = innerWidth ? Math.round(0.9 * innerWidth) : 300;
  let maxFontSize = innerWidth ? Math.min(innerWidth / 6, 110) : 100;

  if (innerWidth >= devices.large) {
    maxFontSize = 165 - currentLocation.name.length * 3;
  } else if (innerWidth >= devices.medium) {
    maxFontSize = 155 - currentLocation.name.length * 3;
  }
  const scaledFont = useFontScaling(
    currentLocation.name,
    maxWidth,
    "Roboto Slab"
  );

  const fontSize = Math.min(maxFontSize, scaledFont);

  return (
    <div
      className="
      relative 
      flex flex-col items-center justify-center 
      w-full 
    "
    >
      <span
        className="
        block 
        mb-2
        text-dynamic5 font-extrabold capitalize 
        text-heading-light dark:text-heading-dark 
        text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
      "
      >
        {format(currentDate, "cccc d.M.y", {
          locale: fi,
        })}
      </span>
      <span
        className="
          block 
          font-extrabold tracking-tight leading-none whitespace-nowrap 
          text-white 
          text-shadow-lg shadow-day-dark dark:shadow-day-dark/30
        "
        style={{ fontSize: fontSize }}
      >
        {currentLocation.name}
      </span>
      <div className="flex items-center mt-4 sm:mt-5">
        <Button
          clicked={() =>
            openModal(
              <MapModal
                name={currentLocation.name}
                latitude={currentLocation.latitude}
                longitude={currentLocation.longitude}
                region="fin"
              />
            )
          }
          label="Sijainti kartalla"
          plEvent="Map"
        >
          <MapIcon width="50%" height="50%" fill="none" stroke="#3cf" />
        </Button>

        <span
          className="
            block 
            mx-5 sm:mx-8 
            text-dynamic5 font-extrabold 
            text-heading-light dark:text-heading-dark  
            text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
          "
        >
          N {localizeDecimals(currentLocation.latitude.toFixed(3))}°, E{" "}
          {localizeDecimals(currentLocation.longitude.toFixed(3))}°
        </span>
        <Button
          clicked={() => {
            findGeoLocation();
          }}
          label="Paikanna"
          plEvent="Locate"
        >
          {positioningStatus === "working" ? (
            <div className="w-5 h-5">
              <Spinner color="#3cf" />
            </div>
          ) : (
            <MapPinIcon width="50%" height="50%" fill="none" stroke="#3cf" />
          )}
        </Button>
      </div>
    </div>
  );
}
