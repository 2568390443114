import { useState, useEffect, useCallback } from "react";

export default function useScaleRatio(ref, initWidth) {
  const [scaleRatio, setScaleRatio] = useState();

  const update = useCallback(() => {
    const ratio = ref.current.offsetParent.clientWidth / initWidth;
    setScaleRatio(ratio);
  }, [ref, initWidth]);

  useEffect(() => {
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  });
  //   console.log(scaleRatio);

  return scaleRatio;
}
