import React, { useState } from "react";
import { useModal } from "../../providers/ModalProvider";
import { CloseIcon } from "../Common/Icons";

const CloseButton = ({ close }) => (
  <button className="absolute top-4 right-4" onClick={close}>
    <CloseIcon width="24px" height="24px" stroke="#aef" fill="none" />
  </button>
);

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function FeedbackModal() {
  const { closeModal } = useModal();
  const [content, setContent] = useState("");
  const [submitResponse, setSubmitResponse] = useState("");

  function handleSubmit(e) {
    const formData = { content };

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "feedback", ...formData }),
    })
      .then(() => setSubmitResponse("success"))
      .catch((error) => {
        setSubmitResponse("fail");
        console.log(error);
      });

    e.preventDefault();
  }

  if (submitResponse) {
    return (
      <div className="w-full sm:w-[500px] p-8 pb-6 bg-day-dark rounded-2xl ">
        <CloseButton close={closeModal} />
        <h2 className="text-2xl font-bold m-0">Palaute</h2>
        <div className="p-6">
          {submitResponse === "success"
            ? "Kiitos palautteestasi!"
            : "Palautteen lähettäminen epäonnistui."}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full sm:w-[500px] p-8 pb-6 bg-day-dark rounded-2xl ">
      <CloseButton close={closeModal} />
      <h2 className="text-3xl font-extrabold m-0">Palaute</h2>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="feedback" />

        <textarea
          className="w-full h-64 p-4 my-4 rounded-lg resize-none text-gray-600"
          name="content"
          id="content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          maxLength="1000"
        />

        <div className="flex justify-end">
          <button
            className="
              py-3 px-8 
              rounded-full 
              bg-day-darker/75  
              hover:bg-day-darker  
              transition-colors 
              disabled:opacity-50 disabled:pointer-events-none 
            "
            type="submit"
            disabled={!content}
          >
            Lähetä
          </button>
        </div>
      </form>
    </div>
  );
}
