export const defaultLocation = {
  name: "Helsinki",
  name_inflected: "Helsingissä",
  latitude: 60.166640739,
  longitude: 24.943536799,
  timeZone: "Europe/Helsinki",
};

export const finLocations = [
  {
    name: "Utsjoki",
    latitude: 69.907828523,
    longitude: 27.026541275,
    timeZone: "Europe/Helsinki",
  },

  {
    name: "Maarianhamina",
    latitude: 60.101520836,
    longitude: 19.942267834,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Oulu",
    latitude: 65.013784817,
    longitude: 25.47209907,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Helsinki",
    latitude: 60.166640739,
    longitude: 24.943536799,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Jyväskylä",
    latitude: 62.241677684,
    longitude: 25.749498121,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Vaasa",
    latitude: 63.092588875,
    longitude: 21.615874122,
    timeZone: "Europe/Helsinki",
  },

  {
    name: "Rovaniemi",
    latitude: 66.502790259,
    longitude: 25.728478856,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Tampere",
    latitude: 61.49774257,
    longitude: 23.761290078,
    timeZone: "Europe/Helsinki",
  },

  {
    name: "Turku",
    latitude: 60.451690351,
    longitude: 22.266866666,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Lahti",
    latitude: 60.980380564,
    longitude: 25.654987962,
    timeZone: "Europe/Helsinki",
  },

  {
    name: "Kuopio",
    latitude: 62.892982923,
    longitude: 27.688934744,
    timeZone: "Europe/Helsinki",
  },
  {
    name: "Kajaani",
    latitude: 64.226733925,
    longitude: 27.728046683,
    timeZone: "Europe/Helsinki",
  },
];

export const worldLocations = [
  {
    name: "Tukholma",
    latitude: 59.333,
    longitude: 18.067,
    timeZone: "Europe/Stockholm",
  },
  {
    name: "Tokio",
    latitude: 35.7,
    longitude: 139.77,
    timeZone: "Asia/Tokyo",
  },
  {
    name: "Los Angeles",
    latitude: 34.05,
    longitude: -118.23,
    timeZone: "America/Los_Angeles",
  },

  {
    name: "Sydney",
    latitude: -33.87,
    longitude: 151.22,
    timeZone: "Australia/Sydney",
  },

  {
    name: "Berliini",
    latitude: 52.33,
    longitude: 13.3,
    timeZone: "Europe/Berlin",
  },

  {
    name: "Lontoo",
    latitude: 51.5,
    longitude: -0.13,
    timeZone: "Europe/London",
  },
  {
    name: "Pariisi",
    latitude: 48.87,
    longitude: 2.67,
    timeZone: "Europe/Paris",
  },
  {
    name: "Rooma",
    latitude: 41.9,
    longitude: 12.48,
    timeZone: "Europe/Rome",
  },

  {
    name: "Moskova",
    latitude: 55.7558,
    longitude: 37.6178,
    timeZone: "Europe/Moscow",
  },

  {
    name: "Bangkok",
    latitude: 13.725,
    longitude: 100.475,
    timeZone: "Asia/Bangkok",
  },
  {
    name: "Peking",
    latitude: 39.92,
    longitude: 116.42,
    timeZone: "Asia/Shanghai",
  },

  {
    name: "Kapkaupunki",
    latitude: -33.925,
    longitude: 18.425,
    timeZone: "Africa/Cairo",
  },

  {
    name: "New York",
    latitude: 40.67,
    longitude: -73.94,
    timeZone: "America/New_York",
  },

  {
    name: "Montreal",
    latitude: 45.55,
    longitude: -73.633,
    timeZone: "America/Montreal",
  },

  {
    name: "Mexico City",
    latitude: 19.4,
    longitude: -99.15,
    timeZone: "America/Mexico_City",
  },
  {
    name: "Rio de Janeiro",
    latitude: -22.9,
    longitude: -43.23,
    timeZone: "America/Sao_Paulo",
  },
  {
    name: "Hongkong",
    latitude: 22.25,
    longitude: 114.17,
    timeZone: "Asia/Hong_Kong",
  },
  {
    name: "Nairobi",
    latitude: -1.266,
    longitude: 36.84,
    timeZone: "Africa/Nairobi",
  },
];

export const gradientColors = {
  light: {
    dawnYellow: "#da8a24",
    dayYellow: "#ffee55",
    duskBlue: "#0284c7",
    nightBlue: "#075895",
  },
  dark: {
    dawnYellow: "#b87414ee",
    dayYellow: "#ffee55",
    duskBlue: "#159",
    nightBlue: "#003774",
  },
};

export const comparisionChartColors = [
  {
    hex: "1eff68",
    rgba: "30,255,104",
  },
  {
    hex: "ff9614",
    rgba: "255, 150, 20",
  },
  {
    hex: "ef9b20",
    rgba: "239,155,324",
  },
  {
    hex: "FFFF6A",
    rgba: "255,255,106",
  },
  {
    hex: "ff4499",
    rgba: "255,68,153",
  },
  {
    hex: "edbf33",
    rgba: "237,191,51",
  },
  {
    hex: "ea5545",
    rgba: "234,85,69",
  },
  {
    hex: "2ED3C9",
    rgba: "46,211,201",
  },
  {
    hex: "C771CB",
    rgba: "199,113,203",
  },
  {
    hex: "87bc45",
    rgba: "135,188,69",
  },
];

export const hasMidnightSun = [
  { name: "Enontekiö", start: "2023-05-24", end: "2023-07-19" },
  { name: "Inari", start: "2023-05-22", end: "2023-07-22" },
  { name: "Kemi", start: "2023-06-20", end: "2023-06-23" },
  { name: "Kemijärvi", start: "2023-06-05", end: "2023-07-08" },
  { name: "Keminmaa", start: "2023-06-17", end: "2023-06-26" },
  { name: "Kittilä", start: "2023-05-29", end: "2023-07-15" },
  { name: "Kolari", start: "2023-05-31", end: "2023-07-13" },
  { name: "Kuusamo", start: "2023-06-13", end: "2023-06-29" },
  { name: "Muonio", start: "2023-05-27", end: "2023-07-17" },
  { name: "Pelkosenniemi", start: "2023-06-01", end: "2023-07-11" },
  { name: "Pello", start: "2023-06-04", end: "2023-07-09" },
  { name: "Posio", start: "2023-06-11", end: "2023-07-02" },
  { name: "Ranua", start: "2023-06-14", end: "2023-06-29" },
  { name: "Rovaniemi", start: "2023-06-06", end: "2023-07-06" },
  { name: "Salla", start: "2023-06-04", end: "2023-07-09" },
  { name: "Savukoski", start: "2023-05-31", end: "2023-07-13" },
  { name: "Sodankylä", start: "2023-05-30", end: "2023-07-13" },
  { name: "Tervola", start: "2023-06-11", end: "2023-07-01" },
  { name: "Tornio", start: "2023-06-16", end: "2023-06-27" },
  { name: "Utsjoki", start: "2023-05-17", end: "2023-07-27" },
  { name: "Ylitornio", start: "2023-06-08", end: "2023-07-04" },
];

export const hasPolarNight = [
  { name: "Enontekiö", start: "2023-12-06", end: "2024-01-06" },
  { name: "Inari", start: "2023-12-02", end: "2024-01-10" },
  { name: "Kittilä", start: "2023-12-14", end: "2023-12-30" },
  { name: "Muonio", start: "2023-12-10", end: "2024-01-02" },
  { name: "Sodankylä", start: "2023-12-20", end: "2023-12-24" },
  { name: "Utsjoki", start: "2023-11-26", end: "2024-01-16" },
];

export const specialEvents = {
  20230320: "marchEquinox",
  20230326: "summerTime",
  20230621: "juneSolstice",
  20230923: "septemberEquinox",
  20231029: "winterTime",
  20231222: "decemberSolstice",

  20240320: "marchEquinox",
  20240331: "summerTime",
  20240620: "juneSolstice",
  20240922: "septemberEquinox",
  20241027: "winterTime",
  20241221: "decemberSolstice",

  20250320: "marchEquinox",
  20250330: "summerTime",
  20250621: "juneSolstice",
  20250922: "septemberEquinox",
  20251026: "winterTime",
  20251221: "decemberSolstice",

  20260320: "marchEquinox",
  20260329: "summerTime",
  20260621: "juneSolstice",
  20260923: "septemberEquinox",
  20261025: "winterTime",
  20261221: "decemberSolstice",
};
