import React from "react";

import CityCard from "./CityCardCompact";

export default function CityList({ cities, region }) {
  const cityList = cities.map((item) => {
    return (
      <CityCard
        key={item.name}
        city={item}
        daylightArch={item.daylightArch}
        localTimeInMinutes={item.currentTimeInMinutes}
        region={region}
      />
    );
  });

  return (
    <ul className="flex flex-wrap justify-center gap-3 p-0 m-0 sm:gap-4">
      {cityList}
    </ul>
  );
}
