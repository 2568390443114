import React from "react";

import logo from "../../assets/logo.svg";
import { useModal } from "../../providers/ModalProvider";
import { InfoIcon, MessageIcon } from "../Common/Icons";

import FeedbackModal from "../Modals/FeedbackModal";
import InfoModal from "../Modals/InfoModal";

export default function Footer() {
  const { openModal } = useModal();

  return (
    <div
      className="
      flex flex-col items-center 
      w-full 
      py-8
      bg-day-dark dark:bg-night-dark 
    "
    >
      <div>
        <img
          className="w-[220px] h-[44px] xs:w-[250px] xs:h-[50px]"
          src={logo}
          alt="PäivänPituus.fi"
        />
      </div>

      <span className="my-3 text-sm font-bold text-heading-dark">
        &copy; 2024 digiluu
      </span>

      <div className="flex items-center gap-2  ">
        <button
          className="flex justify-center items-center w-8 h-8"
          onClick={() => openModal(<InfoModal />)}
          aria-label="Info"
        >
          <InfoIcon width="24px" height="24px" stroke="#fff" fill="none" />
        </button>

        <button
          className="flex justify-center items-center w-8 h-8"
          onClick={() => openModal(<FeedbackModal />)}
          aria-label="Palaute"
        >
          <MessageIcon width="22px" height="22px" stroke="#fff" fill="none" />
        </button>
      </div>
    </div>
  );
}
