import React from "react";

export default function MapModal({ name, latitude, longitude, region }) {
  return (
    <div
      className="w-full flex flex-col justify-center items-center p-2 sm:p-4  bg-day-dark rounded-2xl 
    "
    >
      <img
        className="w-full sm:max-w-lg h-full sm:max-h-lg border-solid border-2 sm:border-4 border-white rounded-xl"
        alt={`${name} kartalla`}
        src={`https://maps.googleapis.com/maps/api/staticmap?
								center=${region === "fin" ? "65.5,26" : latitude + "," + longitude}&
								zoom=${region === "fin" ? "4" : "3"}&
								size=320x320&
								scale=2&
								markers=size:small|${latitude},${longitude}&
								style=feature:administrative.country|element:labels.text|visibility:off&
								style=feature:administrative.country|element:labels.text.stroke|visibility:off&
								style=feature:administrative.province|visibility:off&
								key=AIzaSyBM1l_dCNL38AtwxGnrd5PDkTqMJ3dV86Q`}
      />
    </div>
  );
}
