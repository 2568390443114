import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

const portalNode = document.getElementById("modal");

export default function ModalPortal({ children }) {
  const [el] = useState(() => document.createElement("div"));
  useEffect(() => {
    portalNode.appendChild(el);

    return () => {
      portalNode.removeChild(el);
    };
  }, [el]);

  return createPortal(children, el);
}
