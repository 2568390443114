import { motion } from "framer-motion";
import React from "react";

export default function ModalBackdrop({ children, close }) {
  return (
    <motion.div
      className="
          fixed 
          top-0 left-0 
          w-full h-full 
          flex items-center justify-center
          bg-gray-900/30 
          z-10
        "
      onClick={close}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      key="backdrop"
    >
      {children}
    </motion.div>
  );
}
