import React from "react";

import { useLocations } from "../../providers/LocationsProvider";
import { getMoonPhase } from "../../utils";

export default React.memo(function Moon() {
  const { currentDate } = useLocations();

  const moonPhase = getMoonPhase(currentDate);

  const imgUrl = new URL(
    `../../assets/moonphase_${moonPhase.offset}.png`,
    import.meta.url
  ).href;

  return (
    <div className="absolute -bottom-2 right-2   sm:bottom-2 lg:bottom-5 -lg:right-5 flex flex-col items-center">
      <div className="w-[40px] h-[40px] xs:w-[50px] xs:h-[50px] sm:w-[60px] sm:h-[60px] overflow-hidden select-none mb-1">
        <img className="w-full h-full" src={imgUrl} alt={moonPhase.name} />
      </div>
      <span className="text-xs text-heading-light dark:text-heading-dark xs:text-sm xs:mt-1 shadow-day-dark dark:shadow-night-darker text-shadow-sm">
        {moonPhase.name}
      </span>
    </div>
  );
});
