export const menuStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6df",
    fontWeight: "bold",
    fontSize: "0.9rem",
    "@media only screen and (min-width: 600px)": {
      fontSize: "1rem",
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0060aaaa",
    borderRadius: "10px",
    border: "none",
    padding: "0.3rem 0.1rem 0.3rem 0.7rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "600",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};
