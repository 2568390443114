import React, { useState } from "react";

import { formatDuration, calculateDaysSinceEvent } from "../../utils";
import { useLocations } from "../../providers/LocationsProvider";

export default function Changes({ showSunDialChange }) {
  const {
    currentLocation: { dayLengthChanges },
    currentDate,
  } = useLocations();

  const [selectedChange, setSelectedChange] = useState("");

  function getSymbol(change) {
    if (Math.abs(change) < 30) {
      return "± ";
    }
    if (change < 0) {
      return "- ";
    } else return "+ ";
  }

  function handleSunDialChange(changeId) {
    setSelectedChange(selectedChange === changeId ? "" : changeId);

    if (changeId === "min") {
      showSunDialChange(calculateDaysSinceEvent(currentDate, "min"));
    } else if (changeId === "max") {
      showSunDialChange(calculateDaysSinceEvent(currentDate, "max"));
    } else {
      showSunDialChange(changeId);
    }
  }

  const changesContent = [
    { id: "day", label: "päivässä", value: 1 },
    { id: "week", label: "viikossa", value: 7 },
    { id: "month", label: "kuukaudessa", value: 30 },
    { id: "min", label: "minimistä*", value: "min" },
    { id: "max", label: "maksimista**", value: "max" },
  ];

  return (
    <>
      <div className="flex w-full flex-col items-center lg:ml-auto">
        <h2
          className="
          mb-6 
          text-xl sm:text-2xl font-extrabold 
          text-heading-light dark:text-heading-dark
          text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
        "
        >
          Päivän pituuden muutos
        </h2>
        <div
          className="
            flex flex-col sm:max-lg:flex-row gap-2 sm:gap-3 sm:max-lg:flex-wrap 
            w-full
          "
        >
          {changesContent.map((item) => {
            return (
              <div
                onClick={() => handleSunDialChange(item.value)}
                key={item.id}
                className={`
                flex items-center justify-between flex-1 
                p-4 sm:p-5 
                rounded-xl 
                cursor-pointer
                hover:opacity-90
                transition-colors
                  ${
                    selectedChange === item.value
                      ? "bg-day-darker/70 dark:bg-night-dark"
                      : "bg-day-darker/30 dark:bg-night-dark/40"
                  }`}
              >
                <span
                  className="               
                  block 
                  mr-4 
                  xs:text-lg sm:text-xl font-bold whitespace-nowrap
                  text-heading-light dark:text-heading-dark 
                  text-shadow-sm shadow-day-dark dark:shadow-day-dark/30 
                "
                >
                  {item.label}
                </span>
                <span
                  className="
                  block
                  xs:text-lg sm:text-xl font-extrabold whitespace-nowrap  
                  text-shadow-sm shadow-day-dark dark:shadow-day-dark/30"
                >
                  {getSymbol(dayLengthChanges[item.id])}
                  {formatDuration(dayLengthChanges[item.id], "seconds", true)}
                </span>
              </div>
            );
          })}
        </div>
        <div
          className="
          mt-6 
          text-center text-sm sm:text-base font-bold 
          text-heading-light dark:text-heading-dark 
          text-shadow-sm shadow-day-dark dark:shadow-day-dark/30
        "
        >
          *) talvipäivänseisaus, **) kesäpäivänseisaus
        </div>
      </div>
    </>
  );
}
